import React, { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import { RotatingSquare } from "react-loader-spinner";

const LoanPackageAdd = () => {
  const { authUser } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState([]);
  const [dataVulue, setDataVulue] = useState({});
  const refSubmitDis = useRef();

  const handleSubmitData = (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    setErrorMessage();
    const userData = { ...dataVulue };
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    axios
      .post(
        `https://rubi.api.royeluniverse.com/api/admin/loan/package/store`,
        userData,
        config
      )
      .then((response) => {
        event.target.reset();
        toast.success(`${response?.data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        refSubmitDis.current.removeAttribute("disabled");
      })
      .catch((error) => {
        refSubmitDis.current.removeAttribute("disabled");
        if (error?.response?.data?.errors) {
          setErrorMessage(error?.response?.data?.errors);
        } else {
          setErrorMessage(error?.response?.data);
        }
      });
  };

  const handleInputBlur = (event) => {
    const value = event.target.value;
    const field = event.target.name;
    const newData = { ...dataVulue };
    newData[field] = value;
    setDataVulue(newData);
  };

  if (!authUser?.mining) {
    if (authUser?.mining === undefined) {
      return (
        <>
          <div className="loader-css">
            <RotatingSquare
              visible={true}
              height="100"
              width="100"
              color="#4fa94d"
              ariaLabel="rotating-square-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <h1>unauthorized</h1>
        </>
      );
    }
  } else {
  }

  return (
    <>
      <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
        <h6 className="page-title">Loan Package add</h6>
        <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins"></div>
      </div>

      <div className="row">
        <div className="col-lg-12 col-md-12 mb-30">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmitData}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="heading" className="required">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        onBlur={handleInputBlur}
                        placeholder="name"
                        id="heading"
                      />
                      {errorMessage?.name && (
                        <span className="text-danger pt-2">
                          {errorMessage?.name?.msg}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 ">
                    <div className="form-group">
                      <label htmlFor="heading" className="required">
                        Days
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="period"
                        onBlur={handleInputBlur}
                        placeholder="Days "
                        id="heading"
                        min="1"
                      />
                      {errorMessage?.period && (
                        <span className="text-danger pt-2">
                          {errorMessage?.period?.msg}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="form-group">
                      <label htmlFor="heading" className="required">
                        Percentage
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="percentage"
                        onBlur={handleInputBlur}
                        placeholder="Percentage "
                        id="heading"
                        min="1"
                      />
                      {errorMessage?.percentage && (
                        <span className="text-danger pt-2">
                          {errorMessage?.percentage?.msg}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="form-group">
                      <label htmlFor="heading" className="required">
                        Minimum Amount
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="minimum_amount"
                        onBlur={handleInputBlur}
                        placeholder="Minimum Amount"
                        id="heading"
                      />
                      {errorMessage?.minimum_amount && (
                        <span className="text-danger pt-2">
                          {errorMessage?.minimum_amount?.msg}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="form-group">
                      <label htmlFor="heading" className="required">
                        Maximum Amount
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="maximum_amount"
                        onBlur={handleInputBlur}
                        placeholder="Maximum Amount"
                        id="heading"
                      />
                      {errorMessage?.maximum_amount && (
                        <span className="text-danger pt-2">
                          {errorMessage?.maximum_amount?.msg}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="form-group">
                      <label htmlFor="heading" className="required">
                        Template
                      </label>
                      <select
                        className="form-control"
                        onBlur={handleInputBlur}
                        name="template"
                        id="role"
                      >
                        <option selected disabled>
                          select
                        </option>
                        <option value="1">Template 1</option>
                        <option value="2">Template 2</option>
                        <option value="3">Template 3</option>
                      </select>
                      {errorMessage?.template && (
                        <span className="text-danger pt-2">
                          {errorMessage?.template?.msg}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <button
                    ref={refSubmitDis}
                    type="submit"
                    className="btn btn-primary w-100 h-45"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanPackageAdd;
